.shepherd-modal-overlay-container {
  -ms-filter: progid:dximagetransform.microsoft.gradient.alpha(Opacity=50);
  filter: alpha(opacity=50);
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100vw;
  z-index: 9997;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: 0.5;
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all;
}

.shepherd-element {
  background: #fff;
  box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.1), 0 22px 35px 3px rgba(0, 0, 0, 0.07), 0 8px 42px 7px rgba(0, 0, 0, 0.06) !important;
  max-width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}
.shepherd-element.shepherd-enabled {
  opacity: 1;
  visibility: visible;
}
.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.shepherd-element *,
.shepherd-element ::after,
.shepherd-element ::before {
  box-sizing: border-box;
}

.shepherd-arrow,
.shepherd-arrow::before {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: -1;
}

.shepherd-arrow::before {
  content: "";
  transform: rotate(45deg);
  background: #fff;
}

.shepherd-element[data-popper-placement^=top] {
  margin-bottom: 16px !important;
}
.shepherd-element[data-popper-placement^=top] > .shepherd-arrow {
  bottom: -8px;
  border: 0;
}
.shepherd-element[data-popper-placement^=bottom] {
  margin-top: 16px !important;
}
.shepherd-element[data-popper-placement^=bottom] > .shepherd-arrow {
  top: -8px;
  border: 0;
}
.shepherd-element[data-popper-placement^=left] {
  margin-right: 16px !important;
}
.shepherd-element[data-popper-placement^=left] > .shepherd-arrow {
  right: -8px;
  border: 0;
}
.shepherd-element[data-popper-placement^=right] {
  margin-left: 16px !important;
}
.shepherd-element[data-popper-placement^=right] > .shepherd-arrow {
  left: -8px;
  border: 0;
}
.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0;
}
.shepherd-element.shepherd-has-title[data-popper-placement^=bottom] > .shepherd-arrow::before {
  background-color: #fff;
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none;
}

.shepherd-header {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  line-height: 1;
  padding: 1rem;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: #fff;
}

.shepherd-title {
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
}

.shepherd-text {
  background: #fff;
  font-size: 1rem;
  line-height: 1.3em;
  max-height: 30vh;
  overflow: auto;
  padding: 0 1rem 1rem;
}

.shepherd-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  background-color: #fff;
}
.shepherd-footer .shepherd-button:last-child {
  margin-right: 0;
}
.shepherd-footer > * {
  margin: 0.25rem;
}
.shepherd-footer .shepherd-progress {
  flex-grow: 1;
  padding: 6px 0;
}

.shepherd-button {
  color: #fff;
  background-color: #000;
  border: solid 1px #000;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
  transition: all 0.15s ease;
}
.shepherd-button:not(:disabled):hover {
  background-color: #333333;
  border-color: #333333;
}
.shepherd-button:not(:disabled):active {
  color: rgba(255, 255, 255, 0.75);
}
.shepherd-button:not(:disabled):focus {
  box-shadow: 0 0 0 0.125rem rgba(0, 0, 0, 0.25);
  outline: initial;
}
.shepherd-button.shepherd-button-secondary {
  background: transparent;
  color: #6e777a;
  border: solid 1px transparent;
}
.shepherd-button.shepherd-button-secondary:not(:disabled):hover, .shepherd-button.shepherd-button-secondary:not(:disabled):focus {
  background-color: rgba(110, 119, 122, 0.1);
  border: solid 1px transparent;
  outline: initial;
  box-shadow: initial;
}
.shepherd-button.shepherd-button-secondary:not(:disabled):active {
  color: #6e777a;
  background: rgba(110, 119, 122, 0.2);
  border: solid 1px transparent;
}
.shepherd-button:disabled {
  cursor: not-allowed;
}

.shepherd-cancel-icon {
  background: transparent;
  border: none;
  color: #6e777a;
  opacity: 0.5;
  font-size: 2em;
  cursor: pointer;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: opacity 0.5s ease;
}
.shepherd-cancel-icon:hover {
  opacity: 0.75;
}
.shepherd-cancel-icon:focus {
  outline: initial;
}

/*# sourceMappingURL=theme-standard.css.map */